export default class VideoCaption {
  setup() {
    document.querySelectorAll('.video-caption').forEach((elem) => {
      elem.addEventListener('click', (e) => {
        const caption = prompt(e.target.dataset.prompt || 'Caption');
        if (caption != null) {
          const csrfToken = document.querySelector("[name='csrf-token']").content

          fetch(e.target.dataset.url, {
            credentials: 'same-origin',
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken },
            body: JSON.stringify({ video: { caption: caption } })
          }).then((response) => {
            if (response.ok) {
              const videoId = e.target.dataset.url.match(/\/(\d+)\.js/)[1];
              document.getElementById('video-caption-' + videoId).innerText = caption;
            }
          })
        }
      })
    })
  }
}
