class TopSearch {

  minLength = 2
  maxResults = 14
  query = null
  urls = {}

  constructor(elementId, auto) {
    this.elem = document.getElementById(elementId);
    if (this.elem != null) {
      this.elem.addEventListener("keyup", (e) => {
        this.onKeyPress(e)
      });
    }
  }

  enableAutocomplete(data) {
    this.auto = M.Autocomplete.init(this.elem, {
      data: data,
      limit: this.maxResults,
      onAutocomplete: (e) => {
        this.onAutocomplete(e);
      },
      minLength: this.minLength,
      sortFunction: false
    });
    this._customRenderDropdown.bind(this.auto);
    this.auto._renderDropdown = this._customRenderDropdown;
  }

  disableAutocomplete() {
    if (this.auto != null) {
      this.auto.destroy();
      this.auto = null;
    }
  }

  onAutocomplete(event) {
    if (this.urls[event] != null) {
      location = this.urls[event];
    }
  }

  onKeyPress(event) {
    if (this.elem.value != this.query) {
      if (this.waitingToSearch != null) {
        clearTimeout(this.waitingToSearch);
        this.waitingToSearch = null;
      }
      this.disableAutocomplete();
      this.query = this.elem.value;
      if (this.query.length >= this.minLength) {
        this.waitAndSearch();
      }
    }
  }

  waitAndSearch() {
    this.waitingToSearch = setTimeout(() => {
      this.search();
    }, 1000)
  }

  search() {
    fetch(`/users.json?term=${this.query}&limit=${this.maxResults}`)
      .then((response) => this.results(response))
      .catch((error) => console.error(error));
  }

  results(response) {
    response.json().then((data) => {
      const resultSet = {}
      data.forEach((result) => {
        resultSet[result.name] = result.photo;
        this.urls[result.name] = result.url;
      })
      this.enableAutocomplete(resultSet);
      this.auto.open();
    });
  }

  // this method overrides the plugin's "_renderDropdown" method
  _customRenderDropdown(data, val) {
    this._resetAutocomplete();

    let matchingData = [];

    // Gather all matching data
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        // Break if past limit
        if (this.count >= this.options.limit) {
          break;
        }

        let entry = {
          data: data[key],
          key: key
        };
        matchingData.push(entry);

        this.count++;
      }
    }

    // Render
    for (let i = 0; i < matchingData.length; i++) {
      let entry = matchingData[i];
      this.container.innerHTML += `
        <li class="top-search-result truncate flow-text">
          <img src="${entry.data}" class="right circle">
          <span>${entry.key}</span>
        </li>
      `
    }
  }

}

export default TopSearch;
