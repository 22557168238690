// https://dbrekalo.github.io/simpleLightbox/
import SimpleLightbox from 'simple-lightbox';
import toggleFullscreen, {
  fullscreenChange,
  isFullscreen,
} from 'toggle-fullscreen';

class PhotoGallery {

  lightbox = null
  checkbox = null
  isFullScreenSupported = true

  constructor(elementId) {
    this.elem = document.getElementById(elementId);
    this.checkbox = document.getElementById('full-screen-gallery')

    if (this.elem) {
      fullscreenChange((e) => {
        this.onExitFullScreen(e);
      }).catch((e) => {
        // seems to be unsupported in Mobile Safari
        this.isFullScreenSupported = false;
      });

      this.lightbox = new SimpleLightbox({
        elements: document.querySelectorAll(`#${elementId} a.photo-gallery-elem`),
        closeOnOverlayClick: false,
        beforeSetContent: () => {
          if (this.isGalleryFullScreen()) {
            this.goFullScreen();
          }
        },
        beforeClose: () => {
          this.exitFullScreen();
        },
        beforeDestroy: () => {

        }
      });
    }
  }

  isGalleryFullScreen() {
    return this.checkbox != null && this.checkbox.checked == true;
  }

  goFullScreen() {
    if (!isFullscreen()) {
      toggleFullscreen(document.body);
    }
  }

  exitFullScreen() {
    if (isFullscreen()) {
      toggleFullscreen(document.body);
    }
  }

  onExitFullScreen(e) {
    if (!isFullscreen()) {
      if (this.lightbox) {
        this.lightbox.close();
      }
    }
  }
}

export default PhotoGallery;
