import {Sortable} from '@shopify/draggable';

export default function SortableList() {
  const containerSelector = '.StackedList';
  const containers = document.querySelectorAll(containerSelector);

  if (containers.length === 0) {
    return false;
  }

  const sortable = new Sortable(containers, {
    draggable: '.StackedListItem--isDraggable',
    handle: '.reposition-handle',
    mirror: {
      appendTo: containerSelector,
      constrainDimensions: true,
    },
  });

  sortable.on('drag:stop', function(event) {
    const container = event.data.sourceContainer;
    const dropped = event.data.source;

    let i = 0;
    for (i = 0; i < container.childNodes; i++) {
      let node = container.childNodes[i];
      if (node.nodeType == Node.ELEMENT_NODE && node.isSameNode(dropped)) break;
    };

    let resourceType, resourceId;
    [resourceType, resourceId] = dropped.id.split('-');

    if (container.dataset.repositionUrl != null) {
      let url = container.dataset.repositionUrl.replace(/\/0/, '/' + resourceId);
      url += '?position=' + i;

      fetch(url, {
        credentials: 'same-origin',
        method: 'PATCH'
      });
    }
  });

  return sortable;
}
