export default class ProgressBarHandler {
  showUpload() {
    const uploadProgressBar = document.getElementById('upload-progress-bar');
    const notUploadingText = document.getElementById('not-uploading');
    const nowUploadingText = document.getElementById('uploading-now');
    if (uploadProgressBar) { uploadProgressBar.classList.remove('hide'); }
    if (notUploadingText) { notUploadingText.classList.add('hide'); }
    if (nowUploadingText) { nowUploadingText.classList.remove('hide'); }
  }

  photoUpload() {
    const addNewPhotoButton = document.getElementById('photo_photo');
    if (addNewPhotoButton) {
      addNewPhotoButton.addEventListener('change', () => {
        this.showUpload();
        document.getElementById('new_photo').submit();
      });
    }
  }

  videoFileUpload() {
    const addNewPhotoButton = document.getElementById('video_video');
    if (addNewPhotoButton) {
      addNewPhotoButton.addEventListener('change', () => {
        this.showUpload();
        document.getElementById('new_video').submit();
      });
    }
  }

  videoLinkUpload() {
    const addNewVideoLink = document.getElementById('add-video-link');
    const newVideoUrlInput = document.getElementById('video_url');
    const newVideoExternalInput = document.getElementById('video_external');

    if (addNewVideoLink) {
      addNewVideoLink.addEventListener('click', (event) => {
        event.preventDefault();

        newVideoUrlInput.value = prompt('URL:');

        if (newVideoUrlInput.value) {
          this.showUpload();

          newVideoExternalInput.value = 'true';
          document.getElementById('new_video').submit();
        }
      });
    }
  }
}
