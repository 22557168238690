import merge from 'lodash/merge';

export default class TrixConfig {
  static textAttributes = {
    red: {
      style: { color: 'red' },
      parser: (element) => { return element.style.color === 'red'; },
      inheritable: true,
      toolbarHTML: 'RED'
    }
  }
  static blockAttributes = {
    heading1: {tagName: 'h5', terminal: true, breakOnReturn: true, group: false}
  }

  static enableConfig() {
    Trix.config.attachments.preview.caption.name = true
    Trix.config.attachments.preview.caption.size = false
    merge(Trix.config.textAttributes, this.textAttributes);
    merge(Trix.config.blockAttributes, this.blockAttributes);
  }

  static updateToolbar() {
    let toolbar = document.querySelector('trix-toolbar');
    let buttonHTML = null;

    if (toolbar) {
      buttonHTML = '';
      for (var btn in this.textAttributes) {
        if (this.textAttributes.hasOwnProperty(btn)) {
          const def = this.textAttributes[btn];
          if (def.toolbarHTML) {
            buttonHTML += this.attributeButtonHTML(btn, def.toolbarHTML);
          }
        }
      }

      for (var btn in this.blockAttributes) {
        if (this.textAttributes.hasOwnProperty(btn)) {
          const def = this.blockAttributes[btn];
          if (def.toolbarHTML) {
            buttonHTML += this.blockButtonHTML(btn, def.toolbarHTML);
          }
        }
      }

      toolbar.querySelector('.trix-button-group--text-tools').insertAdjacentHTML('beforeend', buttonHTML);
    }
  }

  static attributeButtonHTML(attr, html) {
    return `<button type='button' class='trix-button' data-trix-attribute='${attr}'>${html}</button>`
  }

  static blockButtonHTML(attr, html) {
    return `<button type='button' class='trix-button' data-trix-attribute='${attr}'>${html}</button>`
  }

  static enableFileUpload() {
    addEventListener('trix-file-accept', (event) => {
      if (!event.target.dataset.ownerGid) {
        event.preventDefault();
        M.toast({html: event.target.dataset.attachmentDisabledReason});
      }
    });
    addEventListener('trix-attachment-add', (event) => {
      if (event.attachment.file) {
        this.uploadFileAttachment(event.attachment, event.target.dataset.ownerGid)
      }
    });
  }

  static uploadFileAttachment(attachment, ownerGid) {
    console.log(attachment, ownerGid);

    const xhr = new XMLHttpRequest();
    const uploadData = new FormData();
    const [none1, none2, none3, assetableType, assetableId] = ownerGid.split('/');

    uploadData.append('attachment[data]', attachment.file);
    uploadData.append('attachment[data_file_name]', attachment.file.name);
    uploadData.append('attachment[data_content_type]', attachment.file.type);
    uploadData.append('attachment[assetable_type]', assetableType);
    uploadData.append('attachment[assetable_id]', assetableId);

    xhr.open('POST', '/attachments.json', true);
    xhr.upload.addEventListener('progress', (event) => {
      attachment.setUploadProgress(event.loaded / event.total * 100 - 10);
    });
    xhr.addEventListener('load', function(event) {
      const response = JSON.parse(xhr.response);
      if (xhr.status == 201) {
        attachment.setUploadProgress(100);
        attachment.setAttributes({
          url: response.asset.url_content,
          href: response.asset.url_content + '?content-disposition=attachment'
        });
      }
    });
    xhr.send(uploadData);
  }
}
