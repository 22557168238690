console.log('Webpacker Web');

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import TrixConfig from './trix-config';
import TopSearch from '../search-autocomplete/search';
import PhotoGallery from '../media/photo-gallery';
import SortableList from '../media/sortable-list';
import ProgressBarHandler from '../media/progress-bar';
import VideoCaption from '../media/video-caption';

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
TrixConfig.enableConfig();

document.addEventListener('DOMContentLoaded', function() {
  TrixConfig.enableFileUpload();
  TrixConfig.updateToolbar();

  new TopSearch('top-search');

  new PhotoGallery('photo-gallery');
  new PhotoGallery('video-gallery');
  new VideoCaption().setup();

  SortableList();

  const progressBarHandler = new ProgressBarHandler();
  progressBarHandler.photoUpload();
  progressBarHandler.videoFileUpload();
  progressBarHandler.videoLinkUpload();
});
